export * from "./inputs";
export * from "./button";


export { default as BBlock } from "./BBlock.vue";
export { default as BBtn } from "./BBtn.vue";
export { default as BCard } from "./BCard.vue";
export { default as BDropdown } from "./BDropdown.vue";
export { default as BError } from "./BError.vue";
export { default as BIcon } from "./BIcon.vue";
export { default as BLoader } from "./BLoader.vue";
export { default as BModal } from "./BModal.vue";
export { default as BOverlay } from "./BOverlay.vue";
export { default as BPage } from "./BPage.vue";
export { default as BSpinner } from "./BSpinner.vue";
export { default as BTooltip } from "./BTooltip.vue";
export { default as BTeleport } from "./BTeleport.vue";
export { default as BProgressBar } from "./BProgressBar.vue";
export { default as BChartPie } from "./BChartPie.vue";
export { default as BCustomDatePicker } from "./BCustomDatePicker.vue";
export { default as BCustomRangeDatePicker } from "./BCustomRangeDatePicker.vue";
export { default as BSliderDetector } from "./BSliderDetector.vue";

export * from "./segment";
export * from "./listbox";
export * from "./scroll";

//#region Global

export const themesValues = ["material", "ios", "styless"] as const;
export const variantValues = [
  "primary",
  "secondary",
  "danger",
  "success",
  "dark",
  "light",
  "transparent",
] as const;
export const sizeValues = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "4xl",
  "full",
  "auto",
] as const;
export const shadowValues = [
  "none",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "inner",
  "sm-light",
] as const;

export type ThemeType = (typeof themesValues)[number];
export type VariantType = (typeof variantValues)[number];
export type SizeType = (typeof sizeValues)[number];
export type ShadowType = (typeof shadowValues)[number];

//#endregion

//#region BBtn

export const btnTypeValues = ["pill", "tile", "rounded", "fab"] as const;

export type ButtonType = (typeof btnTypeValues)[number];

//#endregion

//#region BCard

export const cardTypeValues = ["rounded", "tile", "shaped"] as const;

export type CardType = (typeof cardTypeValues)[number];
export type CardClass = {
  header?: string;
  footer?: string;
  main?: string;
  content?: string;
  loading?: string;
  loadingBar?: string;
};

//#endregion

//#region BIcon

export const iconTypeValues = [
  "preview",
  "annonce",
  "preview_bis",
  "more_vertical",
  "more",
  "close",
  "cancel",
  "contact",
  "edit",
  "book",
  "hourglass",
  "download",
  "download_bis",
  "done",
  "consultation",
  "date",
  "group",
  "event",
  "location",
  "notification",
  "user",
  "candidature",
  "folder",
  "information",
  "question",
  "select",
  "offer",
  "grid",
  "table",
  "add_file",
  "file",
  "skill",
  "identity",
  "mail",
  "candidature_bis",
  "dce_bis",
  "information_bis",
  "offer_bis",
  "question_bis",
  "select_bis",
  "phone",
  "time_left",
  "bimoffice",
  "bimoffice_dark",
  "bimoffice_small",
  "fold",
  "unfold",
  "copy",
  "copy_bis",
  "export",
  "refresh",
  "plus_circle",
  "menu",
  "menu_close",
  "archive",
  "negociation",
  "terminer",
  "info_circle",
  "hide_bis",
  "fit_all",
  "fit_selection",
  "calc",
  "hide",
  "inverser",
  "section",
  "xray",
  "history",
  "stair",
  "layer",
  "layer_half",
  "setting",
  "bordereau",
  "favoris",
  "maquette",
  "search",
  "star",
  "star_outline",
  "chevron_right",
  "chevron_left",
  "chevron_down",
  "chevron_up",
  "warning",
  "ifc_menu_recherche",
  "zoom_in",
  "zoom_out",
  "thumb",
  "filter",
  "upload",
  "read_book",
  "net",
  "manuelle",
  "trash",
  "add",
  "base",
  "frais",
  "pointage",
  "taches",
  "gestion_perso",
  "intervenants",
  "donnees",
  "stats",
  "note_de_frais",
  "file_any",
  "file_pdf",
  "file_xls",
  "file_xlsx",
  "file_png",
  "file_svg",
  "file_jpg",
  "file_txt",
  "file_doc",
  "file_docx",
  "upload_bis",
  "reload",
  "checked",
  "unchecked",
  "clock",
  "bell",
  "send",
  "file_add",
  "file_zip",
  "file_any2",
  "favoris2",
  "note",
  "mobile",
  "box",
  "infos",
  "grid2x2",
  "tri",
  "pulse",
  "home",
  "share",
  "compta",
  "print",
  "valide"
] as const;

export const iconBimTypeValues = ["zone"] as const;

export type IconType = (typeof iconTypeValues)[number];
export type IconBimType = (typeof iconBimTypeValues)[number];

//#endregion
